<template>
  <edit-info :editInfoList="editInfoList" @submitfn="submitForm"></edit-info>
</template>

<script>
import EditInfo from "../BaseInfo/editInfo";
import { editInformation,getUserInfo } from "@/api";
import { isPhoneNum } from "@/utils/validate";
export default {
  components: { EditInfo },
  data() {
    return {
      editInfoList: [
        { name: "总包部名称", field: "name", disable: true },
        { name: "所属总包部名称", field: "company_name", disable: true },
        { name: "管理员姓名", field: "people_name" },
        { name: "联系电话", field: "phone_number" },
        { name: "设置监督岗总数", field: "sentry_num", disable: true },
        { name: "", field: "", disable: true }
      ]
    };
  },
  methods: {
    async submitForm(form) {
      try {
        if (!isPhoneNum(form.phone_number)) {
          throw new Error("手机格式错误");
        }
        delete form["company_name"];
        const requestData = {
          type: 3,
          value: form
        };
        const res = await editInformation(requestData);
        if (res.status == 200) {
          const res = await getUserInfo();
          const userInfo = res.data.data
          localStorage.setItem("userInfo",JSON.stringify(userInfo))
          this.$success("修改成功");
          this.$router.back();
        }
      } catch (e) {
        if (e instanceof Error) {
          this.$message(e.message);
        }
      }
    }
  }
};
</script>

<style scoped>
</style>
